<template>
  <button class="btn md__btn-secondary" @click.prevent="handleClick">
    Editar
    <!-- <i class="el-icon-edit-outline"></i> -->
  </button>
</template>

<script>
export default {
  name: "ButtonEditComponent",

  methods: {
    handleClick() {
      this.$emit("onEditClick");
    }
  }
};
</script>

<style></style>
